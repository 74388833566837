<div class="container-fluid p-md-4">
  <app-page-header title="Allocation Summary"></app-page-header>

  <div *ngIf="!utilService.spinnerShown" style="height: 100%">
    <div class="container">
      <div class="mat-card-subtitle mt-3 mb-2">
        Below is the breakdown of your Reef tokens and the recipient address.
        <br>Please double check and confirm by clicking on the button at the bottom.
        <br>The full tokenomics can be found <a href="https://reef.finance/tokenomics"
                                                target="_blank">here.</a>
        <div class="mt-4 mb-4" *ngIf="hasChildren > 0">
          <p class="no-margin" style="color: indianred">You have splits. This is the original allocation</p>
          <mat-slide-toggle [(ngModel)]="withoutSplits" color="primary">View without splits
          </mat-slide-toggle>
        </div>
      </div>

      <div>
        <div class="row">
          <div class="col-md-6">
            <h3 class="no-margin"><b>Your Tokens</b></h3>
            <div class="card">
              <table mat-table [dataSource]="getTokenState" class="card-body">
                <!-- Position Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> Name</th>
                  <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="amount">
                  <th mat-header-cell *matHeaderCellDef> Amount (REEF)</th>
                  <td mat-cell *matCellDef="let element" class="text-monospace"> {{element.amount | number: '1.0-0'}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="['name', 'amount']"></tr>
                <tr mat-row *matRowDef="let row; columns: ['name', 'amount'];"></tr>
              </table>
            </div>
          </div>
          <div class="col-md-6">
            <h3 class="no-margin"><b>Term</b></h3>
            <div class="card">
              <table mat-table [dataSource]="terms" class="card-body">
                <!-- Position Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> Round</th>
                  <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="price">
                  <th mat-header-cell *matHeaderCellDef> Price (USD)</th>
                  <td mat-cell *matCellDef="let element" class="text-monospace"> {{element.price | currency: 'USD': '$': '1.5-5'}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="['name', 'price']"></tr>
                <tr mat-row *matRowDef="let row; columns: ['name', 'price'];"></tr>
              </table>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-6">
            <div class="card">
              <div class="card-body">
                <h4>Recipient ETH Address:</h4>
                <p class="text-monospace" *ngFor="let address of getAddresses"><b>{{address}}</b></p>

                <h4>Recipient Reef Chain Address:</h4>
                <p class="text-monospace"><b>{{userService.user.ss58address || 'User doesnt have Reef Chain Address set up'}}</b></p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col" *ngIf="!userService.user.summary_accepted">
            <mat-slide-toggle [(ngModel)]="summary_accepted" color="primary">The information above is correct.
            </mat-slide-toggle>
          </div>
        </div>
      </div>
      <div>
        <div class="row" *ngIf="!userService.user.summary_accepted">
          <div class="col-12 col-sm-3 mt-3">
            <button class="btn btn-lg btn-primary btn-block font-weight-bold"  (click)="saveSummary()" [disabled]="!summary_accepted">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
