import { Injectable } from '@angular/core';
import {AlertComponent} from "../components/alert/alert.component";
import {MatDialog} from "@angular/material/dialog";
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";
import {PromptComponent} from "../components/prompt/prompt.component";

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  spinnerShown;
  spinnerText: string;

  constructor(private dialog: MatDialog, private spinner: NgxSpinnerService, private toastr: ToastrService) { }
  showSuccessToastr(message, title?) {
    this.toastr.success(message, title);
  }
  showErrorToastr(message, title?) {
    this.toastr.error(message, title);
  }
  async timeout(seconds) {
    return new Promise((resolve) => {
      setTimeout(() => {
          resolve();
      }, 1000 * seconds)
    })
  }
  spin(name = undefined, show: boolean, text = 'Loading') {
    if (show && !this.spinnerShown) {
      this.spinnerShown = true;
      this.spinnerText = text;
      this.spinner.show(name,  {
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: false
      });
    } else if (!show) {
      this.spinnerShown = false;
      this.spinner.hide(name);
    }
  }
  showInfoMessage(title, message?, btnLabel?, cb?) {
    const dialogRef = this.dialog.open(AlertComponent, {
      data: {title, message, btnLabel},
      minWidth: '20%',
      maxWidth: '30%',
      minHeight: '10%',
      hasBackdrop: true,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(data => {
      if (cb) cb();
    })
  }

  showPrompt(data, large = false) {
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(PromptComponent, {
        width: '250px',
        data,
        hasBackdrop: true,
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        resolve(result);
      });
    });
  }
}
