import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {MonitoringService} from '../../services/monitoring.service';

@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss']
})
export class MonitoringComponent implements OnInit {
  // endpoint = environment.monitoringEndpoint;
  //
  // fetchData(projectName, chain): any[] {
  //   return [
  //     {name: "Whales", endpoint: `/${projectName}?chain=${chain}&addresses=whales`},
  //     {name: "Treasury Liquid", endpoint: `/${projectName}?chain=${chain}&addresses=treasury_liquid`},
  //     {name: "Treasury Vested", endpoint: `/${projectName}?chain=${chain}&addresses=treasury_vested`},
  //     {name: "Exchanges", endpoint: `/${projectName}?chain=${chain}&addresses=exchanges`},
  //     {name: "Infrastructure", endpoint: `/${projectName}?chain=${chain}&addresses=infrastructure`},
  //
  //   ]
  // }
  // baskets = [];
  // totalMonitored = 0;
  addressFilter: string;
  constructor(private http: HttpClient, public monitoringService: MonitoringService) {
    // this.baskets = this.fetchData("om", "ETH");
    // this.baskets.forEach((basket) => {
    //   this.http.get(`${this.endpoint}/balances${basket.endpoint}`).subscribe((data: any) => {
    //     this.totalMonitored += data.amount;
    //     basket.data = data;
    //     console.log(basket, "Yes")
    //   });
    // })
  }

  ngBeforeInit() {

  }

  ngOnInit(): void {

  }
}
