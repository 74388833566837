
<div class="container-fluid p-md-1" *ngIf="monitoringService.statsLoaded">
  <app-page-header title="Address Monitoring Comparing"></app-page-header>

  <div style="height: 100%">

    <div class="container-fluid pt-1" style="padding: 0">
      <!--<div class="row">-->

      <div class="row">
        <div class="col-sm-2"></div>
        <div class="col-md-4 col-sm-12 m-2">
          <label>Search</label>
          <input type="text" class="form-control" [(ngModel)]="addressFilter">
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2">
          <div class="row">
            <div class="col-sm-12 ml-2 mb-2" >
              <button mat-raised-button color="primary" (click)="monitoringService.initiateSnapshot(monitoringService.activeProject.symbol)">Init snapshot</button>
            </div>

          </div>
          <mat-slide-toggle color="primary" [(ngModel)]="deltasOnly">Deltas only</mat-slide-toggle>
          <div>
            Pick a snapshot
            <mat-selection-list [multiple]="false" color="primary" #monitoringService.snapshotsNames>

              <mat-list-option [class.bg-red-imp]="snapshot === monitoringService.comparingSnapshotName" [value]="monitoringService.comparingSnapshotName" (click)="monitoringService.fetchSnapshot(snapshot)" *ngFor="let snapshot of monitoringService.snapshotsNames; let i = index">
                {{i+1}}. {{formatSnapshotName(snapshot)}}
              </mat-list-option>

            </mat-selection-list>

<!--            <ul>-->
<!--              <li class="ptr p-1" *ngFor="let snapshot of monitoringService.snapshotsNames; let i = index" (click)="monitoringService.fetchSnapshot(snapshot)" [ngClass]="{'selectedListItem': monitoringService.comparingSnapshotName === snapshot}">-->
<!--                {{i+1}}. {{formatSnapshotName(snapshot)}}-->
<!--              </li>-->
<!--            </ul>-->

          </div>
        </div>

        <div class="col-sm-5" *ngIf="monitoringService.statsToCompareWithLoaded">

          <div class="card">
            <div class="" >
              <div class="text-center">
                <p class="text-monospace">{{monitoringService.comparingSnapshotName}}</p>
                <h6 class="text-uppercase mb-5 text-monospace mt-2">Total monitored: {{monitoringService.totalMonitoredCompareWith | number: '1.0-0' }} {{monitoringService.activeProject.symbol}}</h6>


              </div>

              <div class="" *ngFor="let basket of monitoringService.basketsToCompareWith">
                <div class="mat-card-content" *ngIf="basket.data">
                  <div (click)="basketToggle(basket)" class="p-2 ptr"><i *ngIf="!basket.show"
                                                                               class="fa fa-chevron-right"></i> <i
                    *ngIf="basket.show" class="fa fa-chevron-down"></i> <b> {{basket.name}}
                    | {{basket.data.amount | number: '1.0-0'}} | {{basket.data.addresses.length}} addresses</b> | <span [ngClass]="{'text-red' : basket.totalDelta > 0}">{{basket.totalDelta}} deltas</span>.</div>
                  <table mat-table [dataSource]="basket.data.addresses | filter: addressFilter" class="card-body" [hidden]="!basket.show">
                    <ng-container matColumnDef="address">
                      <th style="width: 30%" mat-header-cell *matHeaderCellDef> Address</th>
                      <td mat-cell *matCellDef="let element" style="font-size: 10px">
                        <!--                      <a [href]="'https://etherscan.io/address/'+ element.address" target="_blank"> {{element.address | ethAddress}} </a>-->
                        <a [href]="'https://etherscan.io/token/0x3593d125a4f7849a1b059e64f4517a86dd60c95d?a='+ element.address" target="_blank"> {{element.address | ethAddress}} </a>

                      </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                      <th style="width: 25%; margin-left: 3%" mat-header-cell *matHeaderCellDef> Name</th>
                      <td mat-cell *matCellDef="let element"
                          style="text-overflow: clip; font-size: 12px">{{element.name}}</td>
                    </ng-container>
                    <ng-container matColumnDef="amount">
                      <th mat-header-cell  *matHeaderCellDef> Amount</th>
                      <td mat-cell *matCellDef="let element" class="text-monospace pull-right"
                          style="font-size: 12px"> {{element.amount | number: '1.0-0' }} <span *ngIf="element.deltaFound" style="font-size: 10px; float: right" [ngClass]="{'text-green': element.delta > 0, 'text-red': element.delta < 0}">({{element.delta | number : '1.0-0'}})</span></td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="['address', 'name', 'amount']"></tr>
                    <tr mat-row [class.hidden]="deltasOnly && !row.deltaFound"  *matRowDef="let row; columns: ['address', 'name', 'amount'];"></tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="mat-divider"></div>
          </div>
        </div>
        <div class="col-sm-5" *ngIf="monitoringService.statsToCompareWithLoaded">
          <div class="card">
            <div class="" >
              <div class="text-center">
                <p class="text-monospace">Current</p>
                <h6 class="text-uppercase mb-5 text-monospace mt-2">Total monitored: {{monitoringService.totalMonitored | number: '1.0-0' }} {{monitoringService.activeProject.symbol}}</h6>
              </div>

              <div class="" *ngFor="let basket of monitoringService.baskets">
                <div class="mat-card-content" *ngIf="basket.data">
                  <div (click)="basketToggle(basket)" class="p-2 ptr"><i *ngIf="!basket.show"
                                                                         class="fa fa-chevron-right"></i> <i
                    *ngIf="basket.show" class="fa fa-chevron-down"></i> <b> {{basket.name}}
                    | {{basket.data.amount | number: '1.0-0'}} | {{basket.data.addresses.length}} addresses</b></div>
                  <table mat-table [dataSource]="basket.data.addresses |  filter: addressFilter" class="card-body" [hidden]="!basket.show">
                    <ng-container matColumnDef="address">
                      <th style="width: 30%" mat-header-cell *matHeaderCellDef> Address</th>
                      <td mat-cell *matCellDef="let element" style="font-size: 10px">
                        <!--                      <a [href]="'https://etherscan.io/address/'+ element.address" target="_blank"> {{element.address | ethAddress}} </a>-->
                        <a [href]="'https://etherscan.io/token/0x3593d125a4f7849a1b059e64f4517a86dd60c95d?a='+ element.address" target="_blank"> {{element.address | ethAddress}} </a>

                      </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                      <th style="width: 50%; margin-left: 3%" mat-header-cell *matHeaderCellDef> Name</th>
                      <td mat-cell *matCellDef="let element"
                          style="text-overflow: clip; font-size: 12px">{{element.name}}</td>
                    </ng-container>
                    <ng-container matColumnDef="amount">
                      <th mat-header-cell *matHeaderCellDef> Amount</th>
                      <td mat-cell *matCellDef="let element" class="text-monospace pull-right"
                          style="font-size: 12px"> {{element.amount | number: '1.0-0' }} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="['address', 'name', 'amount']"></tr>
                    <tr mat-row [class.hidden]="deltasOnly && !row.deltaFound" *matRowDef="let row; columns: ['address', 'name', 'amount'];"></tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="mat-divider"></div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
