<div class="container" style="text-align: center; margin-top: 10%" *ngIf="!userService.logged">
  <h3>You need a unique url or code to access this site.</h3>
  <div class="container mt-3 mt-md-5 mb-3 mb-md-5 w-100 max-w-xs">
    <div class="card border-0 mx-auto rounded-lg">
      <div class="card-body p-md-5">
        <div class="mb-4 h2 font-weight-bold text-body">Sign In</div>

        <form class="mb-3" id="new_user" action="/users/sign_in" accept-charset="UTF-8" method="post"><input type="hidden" name="authenticity_token" value="gpHZ5uyi269NZSx5nP5M7tx44c7nbffuyL181ZVGnBdRHAM1dhWoKVQTQBh2HR3dfc1XkfCB70C5niMeKE1VOg=="><div class="form-group">
        </div>
          <div class="form-group">
            <input autocomplete="password" [ngModelOptions]="{standalone: true}" [(ngModel)]="manualCode" class="form-control form-control-lg" placeholder="Code" type="password" name="user[password]" id="user_password">
          </div>
<!--          <div class="form-group">-->
<!--            <button (click)="userService.fetch(manualCode)" [disabled]="!manualCode" class="btn btn-lg btn-primary btn-block font-weight-bold">Login</button>-->
<!--          </div>-->
          <div class="form-group">
            <button (click)="monitoringService.login(manualCode)" [disabled]="!manualCode" class="btn btn-lg btn-primary btn-block font-weight-bold">Login</button>
          </div>
        </form>


      </div>
    </div>
  </div>
</div>
