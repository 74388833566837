<svg *ngIf="icon == 'credit-card'" height="16" class="bi bi-credit-card mr-2" viewBox="0 0 16 16" fill="currentColor"
     version="1.1"
     width="16" aria-hidden="true">
  <path fill-rule="evenodd"
        d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z"></path>
  <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z"></path>
</svg>

<svg *ngIf="icon == 'cash-stack'" height="16" class="bi bi-cash-stack mr-2" viewBox="0 0 16 16" fill="currentColor"
     version="1.1"
     width="16" aria-hidden="true">
  <path d="M14 3H1a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1h-1z"></path>
  <path fill-rule="evenodd"
        d="M15 5H1v8h14V5zM1 4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H1z"></path>
  <path
    d="M13 5a2 2 0 0 0 2 2V5h-2zM3 5a2 2 0 0 1-2 2V5h2zm10 8a2 2 0 0 1 2-2v2h-2zM3 13a2 2 0 0 0-2-2v2h2zm7-4a2 2 0 1 1-4 0 2 2 0 0 1 4 0z"></path>
</svg>
<svg *ngIf="icon == 'settings'" height="16" class="bi bi-sliders mr-2" viewBox="0 0 16 16" fill="currentColor"
     version="1.1" width="16"
     aria-hidden="true">
  <path fill-rule="evenodd"
        d="M14 3.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0zM11.5 5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM7 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0zM4.5 10a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm9.5 3.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0zM11.5 15a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"></path>
  <path fill-rule="evenodd"
        d="M9.5 4H0V3h9.5v1zM16 4h-2.5V3H16v1zM9.5 14H0v-1h9.5v1zm6.5 0h-2.5v-1H16v1zM6.5 9H16V8H6.5v1zM0 9h2.5V8H0v1z"></path>
</svg>
<svg *ngIf="icon == 'account'" height="16" class="bi bi-person mr-2" viewBox="0 0 16 16" fill="currentColor"
     version="1.1" width="16"
     aria-hidden="true">
  <path fill-rule="evenodd"
        d="M13 14s1 0 1-1-1-4-6-4-6 3-6 4 1 1 1 1h10zm-9.995-.944v-.002.002zM3.022 13h9.956a.274.274 0 0 0 .014-.002l.008-.002c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664a1.05 1.05 0 0 0 .022.004zm9.974.056v-.002.002zM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"></path>
</svg>
<svg *ngIf="icon == 'signout'" height="16" class="bi bi-door-open mr-2" viewBox="0 0 16 16" fill="currentColor"
     version="1.1" width="16"
     aria-hidden="true">
  <path fill-rule="evenodd"
        d="M1 15.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5zM11.5 2H11V1h.5A1.5 1.5 0 0 1 13 2.5V15h-1V2.5a.5.5 0 0 0-.5-.5z"></path>
  <path fill-rule="evenodd"
        d="M10.828.122A.5.5 0 0 1 11 .5V15h-1V1.077l-6 .857V15H3V1.5a.5.5 0 0 1 .43-.495l7-1a.5.5 0 0 1 .398.117z"></path>
  <path d="M8 9c0 .552.224 1 .5 1s.5-.448.5-1-.224-1-.5-1-.5.448-.5 1z"></path>
</svg>
<svg *ngIf="icon == 'chart'" height="16" class="bi bi-bar-chart mr-2" viewBox="0 0 16 16" fill="currentColor"
     version="1.1" width="16" aria-hidden="true">
  <path fill-rule="evenodd"
        d="M4 11H2v3h2v-3zm5-4H7v7h2V7zm5-5h-2v12h2V2zm-2-1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1h-2zM6 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm-5 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3z"></path>
</svg>
<svg *ngIf="icon == 'archive'" height="16" class="bi bi-bar-chart mr-2" viewBox="0 0 16 16" fill="currentColor"
     version="1.1" width="16" aria-hidden="true">
  <path fill-rule="evenodd"
        d="M2 5v7.5c0 .864.642 1.5 1.357 1.5h9.286c.715 0 1.357-.636 1.357-1.5V5h1v7.5c0 1.345-1.021 2.5-2.357 2.5H3.357C2.021 15 1 13.845 1 12.5V5h1z"></path>
  <path fill-rule="evenodd"
        d="M5.5 7.5A.5.5 0 0 1 6 7h4a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5zM15 2H1v2h14V2zM1 1a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H1z"></path>
</svg>


<img style="height: 16px; width: 16px" class="mr-2" src="/assets/icons/binance.png" *ngIf="icon == 'binance'" alt="">

<img style="height: 16px; width: 16px; margin-bottom: 2px;" class="mr-2" src="/assets/icons/reef-token.svg" *ngIf="icon == 'reef'" alt="">
