import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  title;
  message;
  btnLabel;
  constructor(@Inject(MAT_DIALOG_DATA) public data: {title: string, message: string, btnLabel: string}) {
    this.title = data.title;
    this.message = data.message;
    this.btnLabel = data.btnLabel;
  }

  ngOnInit(): void {
  }

}
