<div class="container-fluid p-md-4">
  <div class="pb-md-4">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-none d-md-inline-flex align-items-center" style="height:32px">
        <h5 class="mb-0 text-body font-weight-bold text-monospace" style="font-size: 1.25rem">
          Manage Allocation [$REEF]
        </h5>
      </div>

    </div>
  </div>

  <div style="height: 100%" [hidden]="utilService.spinnerShown">
    <div class="containerx">
      <div class="container pt-3">
        <div class="row mb-3">
          <div class="col-12 text-monospace">TGE Amount: {{userService.user.tge_amount | number: '1.0-0'}} REEF</div>
          <div class="col-12 mb-4 text-monospace">Split Amount: {{getSplitAmount | number: '1.0-0'}} REEF ({{(getSplitAmount / userService.user.tge_amount * 100) | number: '1.1-2'}}%)</div>
<!--          <div class="col-12 mb-4 text-monospace">Percentage Left to split: {{(100 - (getSplitAmount / userService.user.tge_amount * 100) | number: '1.1-2')}}%</div>-->
          <div class="col-12 mb-3" *ngFor="let child of userService.user.children">
            <div class="card rounded-lg">
              <div class="card-body" [class.new-split]="!child.code">
                <div class="row align-items-center">
                  <div class="col-12 col-md-2 text-right">
                    <div class="small d-flex align-items-center justify-content-end text-muted text-right mb-1">
                      TGE Supply
                    </div>
                    <div class="h6 mb-0 font-weight-bold text-monospace text-right">
                      {{(userService.user.tge_amount * child.percentage / 100) | number: '1.0-0'}}
                    </div>
                    <div class="text-muted"><span *ngIf="!child.code" style="color: indianred">Not saved</span></div>
                  </div>
                  <div class="col-4 col-md-2 mb-4 mb-md-0">
                    <label class="small">Percentage</label>
                    <input [disabled]="child.code" step="0.1" oninput="this.value = this.value > 100 ? 100 : this.value < 0 ? 0 : this.value" [(ngModel)]="child.percentage" max="100" min="0" class="form-control" type="number">
                  </div>
                  <div class="col-12 col-md mb-4 mb-md-0">
                    <label class="small">ETH Address</label>
                    <input [disabled]="child.code" [(ngModel)]="child.address" class="form-control" type="text">
                  </div>
                  <div class="col-12 col-md-2 my-3 my-md-0 flex flex-column">
                    <label class="small">Name</label>
                    <input [disabled]="child.code" [(ngModel)]="child.name" class="form-control" type="text">
                  </div>
                  <div class="col-12 col-md-2 text-right font-weight-bold">
                    <button class="btn btn-block font-weight-bold btn-primary" *ngIf="!child.code"
                       (click)="createChild(child)" [disabled]="child.saving">{{child.saving ? 'Saving..' : 'Save'}}</button>
                    <a class="btn btn-block font-weight-bold btn-light" *ngIf="child.code" target="_blank" [routerLink]="['/preview']" [queryParams]="{code: child.code}"
                       >Preview</a>
                    <button *ngIf="child.code" class="btn btn-block font-weight-bold btn-primary" (cbOnSuccess)="linkCopied(child)" ngxClipboard [cbContent]="getLink(child)">{{child.copied ? 'Copied!' : 'Copy Link'}}</button>

<!--                    <a class="btn btn-block font-weight-bold btn-light"-->
<!--                       (click)="deleteChild(child)"><i *ngIf="!child.deleting" class="fa fa-trash"></i> <span *ngIf="child.deleting">Deleting..</span></a>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="userService.user.children == 0" class="row">
          <div class="col text-center">
            <h3>Allocation Splitting is disabled.</h3>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
