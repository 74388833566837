import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-otc-stats',
  templateUrl: './otc-stats.component.html',
  styleUrls: ['./otc-stats.component.scss']
})
export class OtcStatsComponent implements OnInit {

  constructor(public userService: UserService) { }

  get supply() {
    return this.userService.user.tge_amount - this.userService.getTgeSubtractAmount
  }
  get percentage() {
    return this.userService.otc.supply_sold / this.supply * 100;
  }
  ngOnInit(): void {
  }

}
