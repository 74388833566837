import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ethAddress'
})
export class EthAddressPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    return value.slice(0,4) + "....." +value.slice(-4);
  }

}
