<div class="container-fluid p-md-4">
  <app-page-header title="OTC Stats"></app-page-header>
  <div style="height: 100%">
    <div class="container pt-5">
      <div class="row text-center">
        <div class="col-12 col-md-10 offset-md-1">
          <div class="card" *ngIf="!userService.otcEnabled || userService.user.claim_type === 3">
            <div class="card-body">
              <h3>OTC Stats Disabled</h3>
            </div>
          </div>

          <div class="card" *ngIf="userService.otcEnabled">
            <div class="card-body">
              <h2>Managed Selling Stats</h2>
              <div class="container">
                <div class="row">
                  <div class="col-12 col-sm-4 mb-1">
                    <div class="card text-left bg-success text-white h-100">
                      <div class="card-body bg-purple">
                        <div class="rotate">
                          <i class="fa fa-user fa-4x"></i>
                        </div>
                        <h6 class="text-uppercase mb-5">Initial Supply Sold Amount</h6>
                        <h1 class="display-4 text-monospace">{{percentage | number: '1.1-2'}}%</h1>
                        <h3 class="no-margin">Initial supply {{supply | number: '1.0-0'}}</h3>
                        <h3 class="no-margin">Supply sold: {{userService.otc.supply_sold | number: '1.0-0'}}</h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-4 mb-1">
                    <div class="card text-left bg-success text-white h-100">
                      <div class="card-body bg-purple">
                        <div class="rotate">
                          <i class="fa fa-list fa-4x"></i>
                        </div>
                        <h6 class="text-uppercase mb-5">Average Price</h6>
                        <h1 class="display-4 text-monospace">{{userService.otc.average_price | number: '1.2-4'}}$</h1>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-4 mb-1">
                    <div class="card text-left bg-success text-white h-100">
                      <div class="card-body bg-purple">
                        <div class="rotate">
                          <i class="fa fa-share fa-4x"></i>
                        </div>
                        <h6 class="text-uppercase mb-5">USDT Value of sold supply</h6>
                        <h1 class="display-4 text-monospace">{{userService.otc.usdt_to_claim | number: '1.0-0'}}$</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
<!--              <div class="mt-5 mb-5">-->
<!--                <img style="height: 200px" src="/assets/chart_placeholder.png" alt="">-->
<!--                <p>There will be a distribution chart here soon.</p>-->
<!--              </div>-->
              <p>Managed selling is over.</p>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

