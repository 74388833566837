<h1 mat-dialog-title>{{title}}</h1>
<div mat-dialog-content>
  <p>{{subtitle}}</p>
</div>
<div class="row">
  <div class="col">
    <button class="btn btn-block btn-light" [mat-dialog-close]="false">No</button>
    <button class="btn btn-block float-right btn-primary" [mat-dialog-close]="true" cdkFocusInitial>Yes</button>
  </div>
</div>
