import { Component, OnInit } from '@angular/core';
import {UserService} from "../../services/user.service";

@Component({
  selector: 'app-launchpool',
  templateUrl: './launchpool.component.html',
  styleUrls: ['./launchpool.component.scss']
})
export class LaunchpoolComponent implements OnInit {
  get getTotalLocked() {
    let total = 0;
    this.userService.launchpool.forEach((pool) => {
      total += pool.data?.totalInvestAmount * pool.price?.price;
    });
    return total;
  }

  constructor(public userService: UserService) { }

  ngOnInit(): void {
  }

}
