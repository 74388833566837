<div class="w-100 mb-3 mb-md-0">
  <div class="d-md-none navbar-mobile-float navbar navbar-expand-md navbar-light bg-white py-3 px-4"
       [class.nav-open]="mobileNavShown">
    <div class="d-flex align-items-center">
      <a class="link-unstyled d-inline-flex align-items-center"><img class="mr-2" style="width:74px; margin-bottom: 5px"
                                                                     [src]="'/assets/'+this.monitoringService.activeProject.logo">
      </a>
      <div class="mb-4 d-flex flex-column">
      </div>
      <h5 class="text-body font-weight-bold text-monospace mb-0 ml-2">
        <div class="h6 mb-1">{{(navRoute | async) || nav}}</div>
      </h5>
    </div>
    <button class="navbar-toggler border-0 px-0" (click)="mobileNavShown = !mobileNavShown">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse mt-3" [class.show]="mobileNavShown" id="menu">
      <mat-form-field>
        <mat-label>Project</mat-label>
        <mat-select (selectionChange)="reinit()" style="z-index: 999999!important;"
          [(ngModel)]="monitoringService.activeProject">
          <mat-option style="z-index: 999999!important;" *ngFor="let project of monitoringService.projects" [value]="project">{{project.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="mb-4 d-flex flex-column">
        <a class="mb-3 h6 text-decoration-none text-body d-flex align-items-center" (click)="mobileNavShown = false"
           [routerLink]="nav.link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
           *ngFor="let nav of getNavigations" [ngClass]="nav.class">
          <app-svg-icon [icon]="nav.icon"></app-svg-icon>
          {{nav.title}}
        </a>
      </div>
      <div class="mb-4 d-flex flex-column">
        <a class="mb-3 h6 text-decoration-none text-body d-flex align-items-center ptr" (click)="signout()">
          <app-svg-icon icon="signout"></app-svg-icon>
          Sign out
        </a></div>

    </div>
  </div>

</div>

<div class="d-flex">
  <div class="d-none d-md-flex bg-white px-4 py-4 flex-column vh-100 sticky-top w-2xs">
    <div class="mb-5">
      <a class="link-unstyled d-inline-flex align-items-center text-center">
        <img class="mr-2" style="width: 180px" [src]="'/assets/'+this.monitoringService.activeProject.logo">
      </a>
      <mat-form-field class="mt-2">
        <mat-label>Project</mat-label>
        <mat-select
          [(ngModel)]="monitoringService.activeProject" (selectionChange)="reinit()">
          <mat-option *ngFor="let project of monitoringService.projects" [value]="project">{{project.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="mb-4">
      <div class="mb-3">
        <a class="d-block link-unstyled">
          <div *ngIf="userService.isPreview">
            <div class="h3" style="color: indianred">
              PREVIEW
            </div>
            <button class="mb-5 btn btn-sm btn-primary" (click)="reload()">
              Go back
            </button>
          </div>
          <div class="h6 mb-1">Hi, {{userService.user.name}}</div>
          <div class="mb-1 small text-body">
            Welcome back.
          </div>
        </a>
      </div>
    </div>


    <div class="mb-4 d-flex flex-column">
      <a class="mb-3 h6 text-decoration-none text-body d-flex align-items-center" [ngClass]="nav.class" routerLinkActive="active"
         [routerLinkActiveOptions]="{exact: true}"
         [routerLink]="nav.link" *ngFor="let nav of getNavigations">
        <app-svg-icon [icon]="nav.icon"></app-svg-icon>
        {{nav.title}}
      </a>
    </div>
    <div class="mb-4 d-flex flex-column">
      <a class="mb-3 h6 text-decoration-none text-body d-flex align-items-center ptr" (click)="signout()">
        <app-svg-icon icon="signout"></app-svg-icon>
        Sign out
      </a></div>

  </div>

  <div class="d-flex flex-column w-100 mt-5 mt-sm-0" *ngIf="visible">
    <router-outlet></router-outlet>

  </div>
</div>
