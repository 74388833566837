import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss']
})
export class PromptComponent implements OnInit {


  @Input() title;
  @Input() subtitle;


  constructor(@Inject(MAT_DIALOG_DATA) public data: {title: string, subtitle: string}) {
    this.title = data.title;
    this.subtitle = data.subtitle;
  }


  ngOnInit() {
  }

}
