import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {UtilService} from './util.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(private utilService: UtilService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (window._jwt) {
      request = request.clone({ headers: request.headers.set('Authorization', window._jwt) });
    }

    // @ts-ignore
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        // if (event instanceof HttpResponse) {
        //   console.log('event--->>>', event);
        // }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        let data = {
          reason: (error && error.error) ? error.error.message || error.error.detail || error.error.error : '',
          status: error.status
        };
        console.log(data, "????ss?")
        this.utilService.showErrorToastr(data.reason || 'An error occurred.');
        this.utilService.spin(undefined,false);
        return throwError(error);
      }));
  }
}
declare const window;
