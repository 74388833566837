<div class="container-fluid p-md-1" *ngIf="monitoringService.statsLoaded">
  <app-page-header title="Address Monitoring"></app-page-header>
  <div style="height: 100%">
    <div class="container pt-5" style="padding: 0">
      <!--<div class="row">-->
      <div class="p-1">

      </div>
      <div class="card">
        <div class="">
          <div class="row">
            <div class="col-sm-12">
              <div class="text-center">
                <h6 class="text-uppercase mb-3 text-monospace mt-2">Total
                  monitored: {{monitoringService.totalMonitored | number: '1.0-0' }} {{monitoringService.activeProject.symbol}}
                  <span *ngIf="monitoringService.activeProject.gsheet_link" style="font-size: 13px"><a [href]="monitoringService.activeProject.gsheet_link" target="_blank">Sheet</a></span>
                </h6>

              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 col-sm-12 m-1">
              <label>Search</label>
              <input type="text" class="form-control" [(ngModel)]="addressFilter">
            </div>
            <div class="col-md-4">
              <div class="mt-4">
<!--                <h6 class="text-uppercase mb-5 text-monospace mt-2">Total-->
<!--                  monitored: {{monitoringService.totalMonitored | number: '1.0-0' }} OM</h6>-->
                <mat-form-field>
                  <mat-label>Network</mat-label>
                  <mat-select [(ngModel)]="monitoringService.currentNetwork"
                              (ngModelChange)="monitoringService.fetchBaskets()">
                    <mat-option *ngFor="let network of ['ETH', 'POLYGON', 'BSC']" [value]="network">{{network}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>


          <div class="" *ngFor="let basket of monitoringService.baskets">
            <div class="mat-card-content" *ngIf="basket.data">
              <div (click)="basket.show = !basket.show" class="p-2 ptr"><i *ngIf="!basket.show"
                                                                           class="fa fa-chevron-right"></i> <i
                *ngIf="basket.show" class="fa fa-chevron-down"></i> <b> {{basket.name}}
                | {{basket.data.amount | number: '1.0-0'}} | {{basket.data.addresses.length}} addresses</b></div>
              <table mat-table [dataSource]="basket.data.addresses | filter: addressFilter" class="card-body"
                     [hidden]="!basket.show">
                <ng-container matColumnDef="address">
                  <th style="width: 30%" mat-header-cell *matHeaderCellDef> Address</th>
                  <td mat-cell *matCellDef="let element" style="font-size: 10px">
                    <!--                      <a [href]="'https://etherscan.io/address/'+ element.address" target="_blank"> {{element.address | ethAddress}} </a>-->
                    <a
                      [href]="'https://etherscan.io/token/0x3593d125a4f7849a1b059e64f4517a86dd60c95d?a='+ element.address"
                      target="_blank"> {{element.address | ethAddress}} </a>

                  </td>
                </ng-container>
                <ng-container matColumnDef="name">
                  <th style="width: 50%; margin-left: 3%" mat-header-cell *matHeaderCellDef> Name</th>
                  <td mat-cell *matCellDef="let element"
                      style="text-overflow: clip; font-size: 12px">{{element.name}}</td>
                </ng-container>
                <ng-container matColumnDef="amount">
                  <th mat-header-cell *matHeaderCellDef> Amount</th>
                  <td mat-cell *matCellDef="let element" class="text-monospace pull-right"
                      style="font-size: 12px"> {{element.amount | number: '1.0-0' }} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="['address', 'name', 'amount']"></tr>
                <tr mat-row *matRowDef="let row; columns: ['address', 'name', 'amount'];"></tr>
              </table>
            </div>
          </div>
        </div>
        <div class="mat-divider"></div>
      </div>
    </div>
  </div>
</div>
