import {Pipe, PipeTransform} from '@angular/core';
import {MonitoringService} from '../services/monitoring.service';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  constructor(private monitoringService: MonitoringService) {
  }
  transform(value: any, ...args: string[]): unknown {
    let search = args[0] || '';
    this.monitoringService.baskets.forEach(q => {
      q.show = search;
    })
    this.monitoringService.basketsToCompareWith.forEach(q => {
      q.show = search;
    })
    return search ? value.filter(q => q.address.toLowerCase().indexOf(search.toLowerCase()) != -1 || q.name.toLowerCase().indexOf(search.toLowerCase()) != -1) : value;
  }

}
