<div class="container-fluid p-md-4" *ngIf="monitoringService.statsLoaded">
  <app-page-header title="Stats"></app-page-header>
  <div style="height: 100%">
    <div class="pt-5">
      <div class="row text-center">
        <div class="col-12 col-md-10 offset-md-1" style="padding: 0">


          <div class="card">
            <div class="">
<!--              <h2>Stats</h2>-->
              <div class="container">
                <div class="row">
                  <div class="col-sm-12 mb-5">
                    <h6 class="text-uppercase text-monospace mt-2">Price: {{monitoringService.priceOm?.usd | number: '1.0-5'}}$</h6>
                    <button mat-raised-button class="btn" color="primary" (click)="calculateAggregatePnl()">Generate average from checked</button>
                  </div>

                  <div class="mb-1 col-sm-12">
                  <div class="card text-left bg-success text-white h-100" *ngIf="checkedAggregateAnalysis">
                    <div class="card-body bg-cyan">
                      <!--                        <div class="rotate">-->
                      <!--                          <i class="fa fa-user fa-4x"></i>-->
                      <!--                        </div>-->
                      <h6 class="text-uppercase mb-5 text-monospace">Acc: {{checkedAggregateAnalysisList()}} <button (click)="resetChecked()" mat-raised-button  style="float: right">Clear</button></h6>
                      <p *ngIf="checkedAggregateAnalysis.time" class="text-monospace">Time: {{checkedAggregateAnalysis.time | date: 'dd.MM.yyyy - HH:mm'}}</p>
                      <p class="text-monospace">Current value: <span style="float: right">${{checkedAggregateAnalysis.totalPositionValue | number: '1.0-0'}} </span></p>

                      <h3 class="text-uppercase mb-5 text-monospace no-margin font-italic">Cost</h3>
                      <p class="text-monospace no-margin">Amount: <span style="float: right">${{checkedAggregateAnalysis.totalPositionCost | number: '1.0-0'}}</span></p>
                      <p class="text-monospace no-margin">Quantity: <span style="float: right">{{checkedAggregateAnalysis.currentPosition | number: '1.0-0'}} </span></p>
                      <p class="text-monospace no-margin">Avg. Buy Price: <span style="float: right">${{checkedAggregateAnalysis.averagePositionPrice | number: '1.0-5'}} </span></p>
                      <div class="m-5"></div>
                      <h3 class="text-uppercase mb-5 text-monospace no-margin font-italic">PNL</h3>
                      <p class=" text-monospace no-margin">Percentage: <span style="float: right">+{{ checkedAggregateAnalysis.profitPercentage | number: '1.0-1'}}%</span></p>
                      <p class=" text-monospace no-margin">USD: <span style="float: right">${{ checkedAggregateAnalysis.unrealizedPnl | number: '1.0-0'}}</span></p>


                    </div>
                  </div>
                  </div>

                  <div class="mb-1" *ngFor="let analysis of monitoringService.analyses" [ngClass]="analysis.account == 'TOTAL' ? 'col-sm-12' : 'col-sm-12'">
                    <div class="card text-left bg-success text-white h-100">
                      <div class="card-body" [ngClass]="analysis.account == 'TOTAL' ? 'bg-orange' : 'bg-purple'">
<!--                        <div class="rotate">-->
<!--                          <i class="fa fa-user fa-4x"></i>-->
<!--                        </div>-->
                        <h6 class="text-uppercase mb-5 text-monospace">Acc: {{analysis.account}} <mat-checkbox color="warn" *ngIf="analysis.account != 'TOTAL'" [(ngModel)]="analysis.checkedForAgg"></mat-checkbox></h6>
                        <p *ngIf="analysis.time" class="text-monospace">Time: {{analysis.time | date: 'dd.MM.yyyy - HH:mm'}}</p>
                        <p class="text-monospace">Current value: <span style="float: right">${{analysis.totalPositionValue | number: '1.0-0'}} </span></p>

                        <h3 class="text-uppercase mb-5 text-monospace no-margin font-italic">Cost</h3>
                        <p class="text-monospace no-margin">Amount: <span style="float: right">${{analysis.totalPositionCost | number: '1.0-0'}}</span></p>
                        <p class="text-monospace no-margin">Quantity: <span style="float: right">{{analysis.currentPosition | number: '1.0-0'}} </span></p>
                        <p class="text-monospace no-margin">Avg. Buy Price: <span style="float: right">${{analysis.averagePositionPrice | number: '1.0-5'}} </span></p>
                        <div class="m-5"></div>
                        <h3 class="text-uppercase mb-5 text-monospace no-margin font-italic">PNL</h3>
                        <p class=" text-monospace no-margin">Percentage: <span style="float: right">+{{ analysis.profitPercentage | number: '1.0-1'}}%</span></p>
                        <p class=" text-monospace no-margin">USD: <span style="float: right">${{ analysis.unrealizedPnl | number: '1.0-0'}}</span></p>


                      </div>
                    </div>
                  </div>
<!--                  <div class="col-12 col-sm-6 mb-1">-->
<!--                    <div class="card text-left bg-success text-white h-100">-->
<!--                      <div class="card-body " [ngClass]="analysis.account == 'TOTAL' ? 'bg-orange' : 'bg-purple'">-->
<!--                        <div class="rotate">-->
<!--                          <i class="fa fa-list fa-4x"></i>-->
<!--                        </div>-->
<!--                        <h6 class="text-uppercase mb-5">PNL</h6>-->
<!--                        <p class=" text-monospace no-margin">Percentage: <span style="float: right">{{ analysis.profitPercentage | number: '1.0-1'}}%</span></p>-->
<!--                        <p class=" text-monospace no-margin">USD: <span style="float: right">{{ analysis.unrealizedPnl | number: '1.0-0'}}$</span></p>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->

                </div>
              </div>
              <!--              <div class="mt-5 mb-5">-->
              <!--                <img style="height: 200px" src="/assets/chart_placeholder.png" alt="">-->
              <!--                <p>There will be a distribution chart here soon.</p>-->
              <!--              </div>-->


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

