<div class="container-fluid p-md-4">
  <app-page-header title="Launchpool Stats"></app-page-header>
  <div style="height: 100%">
    <div class="container pt-5">
      <div class="row text-center">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h2 class="text-monospace no-margin">Farms</h2>
              <p class="text-monospace">Total Locked: {{getTotalLocked | number: '1.0-0'}} USD</p>
              <div class="container">
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-4 mb-1" *ngFor="let pool of userService.launchpool">
                    <div class="card text-left bg-light h-100">
                      <div class="card-body">
                        <div class="rotate">
                          <i class="fa fa-database fa-4x"></i>
                        </div>
                        <div class="mb-5 text-center">
                          <h2 class="text-monospace">[{{pool.data?.asset}} - {{pool.data?.rebateCoin}}]</h2>
                          <img *ngIf="pool.data?.asset" style="height: 50px;" [src]="'/assets/icons/'+pool.data?.asset.toLowerCase()+'.svg'" alt="">
                          <img style="height: 50px;" src="/assets/icons/reef-token.svg" alt="">
                        </div>
                        <h6 class="text-uppercase no-margin">Total {{pool.data?.asset}} Staked</h6>
                        <h3 class="text-monospace no-margin">{{pool.data?.totalInvestAmount | number:'1.0-0'}} {{pool.data?.asset}}</h3>
                        <div class="text-muted mb-3">{{(pool.data?.totalInvestAmount * pool.price?.price) | number:'1.0-0'}} USD</div>
                        <p class="no-margin">Total {{pool.data?.asset}} Pool Rewards</p>
                        <p class="text-monospace mb-3">{{pool.data?.rebateTotalAmount | number:'1.0-0'}} {{pool.data?.rebateCoin}}</p>
                        <p class="no-margin">Today's {{pool.data?.asset}} Pool Rewards</p>
                        <p class="text-monospace mb-3">{{pool.data?.todayRebateCoins | number:'1.0-0'}} {{pool.data?.rebateCoin}}</p>
                        <p class="no-margin">Annual percentage yield</p>
                        <p class="text-monospace mb-3">{{(pool.data?.annualRate | number:'1.0-0') || 'TBD '}}%</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

