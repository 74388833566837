import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import * as moment from 'moment';
import {UtilService} from "./util.service";
import {Router} from "@angular/router";
import {Subject} from "rxjs";
@Injectable({
  providedIn: 'root'
})
export class UserService {
  endpoint = environment.endpoint;
  user: any = {children: []};
  tge = moment.unix(1609221600).toDate();
  //nextClaimableDate = //moment(this.tge).add('3', 'months').toDate();
  nextClaimableDateIn = moment('31.12.2021', 'DD.MM.YYYY').diff(moment(), 'days') + 1;
  code;
  logged: any;
  loggedEmitter = new Subject();
  isPreview: boolean;
  launchpool: any = [];
  prices: any = { 'BNB': 30, 'BUSD': 1 };
  allocationSubtractAmount: number = 0;
  otc;
  otcEnabled = true;
  constructor(private http: HttpClient, private utilService: UtilService, private router: Router) {
    // const urlParams = new URLSearchParams(window.location.search);
    // const code = urlParams.get('code') || localStorage.getItem('code');
    // if (code) {
    //   this.fetch(code, (action) => {
    //     if (action) {
    //       if (urlParams.get('code')) this.isPreview ? this.router.navigate(['/allocation-summary']) : this.router.navigate(['/']);
    //     }
    //   });
    // } else {
    //   this.utilService.spin(undefined, false);
    //   this.router.navigate(['/login'])
    // }

    // setInterval(() => {
    //   this.fetchLaunchpoolData();
    //   if (this.otcEnabled && this.user.claim_type == 2) {
    //     this.fetchOtcStats();
    //   }
    //
    // }, 60 * 1000)

  }
  fetch(manualCode?, cb?) {
    this.isPreview = this.router.url.indexOf('preview') != -1;
    this.utilService.spin(undefined, true, 'Logging in..');

    this.fetchData(manualCode || this.code).subscribe((data: any) => {
      this.utilService.spin(undefined, false, 'Logging in..');
      if (!data) {
        this.code = '';
        if (!this.isPreview) {
          this.utilService.showInfoMessage('Access denied. Code invalid');
          this.logout();
        } else {
          this.utilService.showInfoMessage('Invalid Link');
          this.router.navigate(['/invalid-link'])
        }
        if (cb) cb(false);
        return;
      } else if (manualCode) {
        this.code = manualCode;
      }
      Object.assign(this.user, data);
      if (this.user.claim_type == 2) {
        this.fetchOtcStats();
      } else {
        this.otcEnabled = false;
      }
      this.fetchLaunchpoolData();
      if (!this.isPreview) {
        localStorage.setItem('code', manualCode);
      }
      this.loggedEmitter.next(true);
      this.getChildren().subscribe(data => {
        this.user.children = data;
      });
      //this.fetchOtcStats();

      if (cb) cb(true);
    }, err => {
      this.logout()

    });

  }

  get getTgeSubtractAmount() {
    let amount = 0;
    this.user.children.filter(x => x.code).forEach(child => {
      if (child.tge_amount) {
        amount += child.tge_amount;
      }
    });
    return amount;
  }

  get getVestSubtractAmount() {
    let amount = 0;
    if (this.user.children) {
      this.user.children.filter(x => x.code).forEach(child => {
        amount += child.vest_amount;
      });
    }
    return amount;
  }
  fetchData(code) {
    this.code = code;
    return this.http.get(`${this.endpoint}/fetch?code=${code}`);
  }
  saveSummary(code) {
    return this.http.post(`${this.endpoint}/summary?code=${code}`, {});
  }
  claim(claim_type) {
    if (!this.code) {
      throw new Error('Code is missing.');
    }
    return this.http.post(`${this.endpoint}/claim?code=${this.code}`, {code: this.code, claim_type});
  }
  saveProgram(code, program) {
    return this.http.post(`${this.endpoint}/program?code=${code}`, program);
  }
  saveProposedPrice(code, proposedPrice) {
    return this.http.post(`${this.endpoint}/proposed_price?code=${code}`, {proposed_price: proposedPrice});
  }

  createChild(child) {
    return this.http.post(`${this.endpoint}/child?code=${this.code}`, child);
  }
  getChildren() {
    return this.http.get(`${this.endpoint}/children?code=${this.code}`);
  }
  fetchLaunchpoolData() {
     this.http.get(`${this.endpoint}/launchpool?code=1`).subscribe(data => {
       this.launchpool = data;
     });
  }
  fetchOtcStats() {
    this.http.get(`${this.endpoint}/otc?code=${this.code}`).subscribe(data => {
      this.otc = data;
    }, err => {
      this.otcEnabled = false;
    });
  }
  deleteChild(child_code) {
    return this.http.delete(`${this.endpoint}/child/${child_code}?code=${this.code}`);
  }
  logout() {
    this.router.navigate(['/login']);
    this.user = {};
    localStorage.clear();
  }
  confirmVestDistribution() {
    return this.http.post(`${this.endpoint}/confirm-vest-distribution?code=${this.code}`, {code: this.code});
  }
  confirmTgeDistribution() {
    return this.http.post(`${this.endpoint}/confirm-tge-distribution?code=${this.code}`, {code: this.code});
  }
  confirmUsdtDistribution() {
    return this.http.post(`${this.endpoint}/confirm-usdt-distribution?code=${this.code}`, {code: this.code});
  }
  validateSs58Address(address) {
    return this.http.get(`${this.endpoint}/validate-substrate?address=${address}`);
  }

  confirmSs58Address(ss58Address: any) {
    return this.http.post(`${this.endpoint}/confirm-ss58-address?code=${this.code}`, {code: this.code, address: ss58Address});
  }
}
