<div class="container-fluid p-md-4">
  <app-page-header title="REEF Claim System"></app-page-header>

  <div style="height: 100%" [hidden]="utilService.spinnerShown">
    <div class="containerx">

<!--      <div class="container pt-3">-->
<!--        <h3 class="no-margin">Upcoming Distributions</h3>-->
<!--        <div class="row text-center">-->


<!--          <div class="mt-2 col-12 col-md-6 col-lg-5">-->
<!--            <div class="card h-100">-->
<!--              <div class="card-body">-->
<!--                &lt;!&ndash;                <h2>Pending Claim</h2>&ndash;&gt;-->
<!--                &lt;!&ndash;                <mat-icon class="claimed-icon" aria-hidden="false" aria-label="Reef Claimed">cached</mat-icon>&ndash;&gt;-->
<!--                <div class="mt-3 mb-3">-->
<!--                  <p>REEF amount</p>-->
<!--                  <h1 class="token-number-text text-monospace">{{userService.user.vest_amount - userService.getVestSubtractAmount | number: '1.0-0'}}</h1>-->
<!--                </div>-->
<!--                <div>-->
<!--                  <p style="color: indianred; font-weight: bold" class="mb-3">Reef Ecosystem is launching this month. This batch will be distributed on 25th of February.</p>-->

<!--                  <div *ngIf="!userService.user.ss58address">-->
<!--                    <p style="color: indianred" class="mb-3">This batch will be distributed on Reef Chain. You need to create and confirm your new address.</p>-->
<!--                    -->
<!--                    <div *ngIf="!alreadyHave && !howToCreate">-->
<!--                      <a class="btn font-weight-bold" href="https://wiki.polkadot.network/docs/learn-account-generation/#polkadotjs-browser-plugin" target="_blank">How do I create Reef Chain address?</a>-->
<!--                      <button class="btn btn-primary font-weight-bold" (click)="alreadyHave = true">I already have Reef Chain address</button>-->
<!--                    </div>-->
<!--                    <div *ngIf="alreadyHave">-->
<!--                      <p><b>Paste your Reef Chain Substrate address (SS58) below</b></p>-->
<!--                      <input [disabled]="confirming" type="text" class="form-control mt-1 mb-2" [(ngModel)]="ss58Address" style="font-size: 13px">-->
<!--                      <p class="no-margin mb-3" style="color: indianred" *ngIf="ss58AddressError">Address is invalid! <a href="https://wiki.polkadot.network/docs/learn-account-generation/#polkadotjs-browser-plugin" target="_blank">Check guide</a></p>-->
<!--                      <button *ngIf="!confirming" class="btn btn-primary btn-block font-weight-bold" (click)="confirmSs58Address()">Confirm</button>-->
<!--                      <button *ngIf="!confirming" class="btn btn-block font-weight-bold" (click)="alreadyHave = false">Back</button>-->

<!--                    </div>-->
<!--                    &lt;!&ndash;                    <div *ngIf="howToCreate">&ndash;&gt;-->

<!--                    &lt;!&ndash;                      <p><b>Navigate to the guide below</b></p>&ndash;&gt;-->
<!--                    &lt;!&ndash;                      <button class="btn btn-primary btn-block font-weight-bold">Hot wallet</button>&ndash;&gt;-->
<!--                    &lt;!&ndash;                      <button class="btn btn-primary btn-block font-weight-bold">Cold wallet</button>&ndash;&gt;-->
<!--                    &lt;!&ndash;                      <button class="btn btn-block font-weight-bold" (click)="howToCreate = false">Back</button>&ndash;&gt;-->
<!--                    &lt;!&ndash;                    </div>&ndash;&gt;-->
<!--                    <p *ngIf="confirming">Confirming...</p>-->
<!--                    &lt;!&ndash;                    <p class="text-center mt-2">If the address it not correct, please contact <a target="_blank" href="https://t.me/reef_claim_admin">@reef_claim_admin</a> on telegram.</p>&ndash;&gt;-->
<!--                  </div>-->

<!--                  <div *ngIf="userService.user.ss58address">-->
<!--                    <div *ngIf="userService.user.vest4_tx == 'false'" >-->
<!--&lt;!&ndash;                      <p style="color: purple; font-weight: bolder">Your tokens will be distributed very soon!</p>&ndash;&gt;-->
<!--                      <p class="m-1">Address: <b>{{userService.user.ss58address}}</b></p>-->
<!--                      <p><br>If the address it not correct, please contact <a target="_blank" href="https://t.me/reef_claim_admin">@reef_claim_admin</a> on telegram.</p>-->
<!--                    </div>-->
<!--                    <div *ngIf="userService.user.vest4_tx !== 'false'">-->
<!--                      <p  style="color: purple; font-weight: bolder">Your tokens have been distributed.</p>-->
<!--                      <p class="m-1">Address: <b>{{userService.user.ss58address}}</b></p>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="container pt-3">
        <h3 class="no-margin">Past Distributions</h3>
        <div class="row text-center">
          <div class="mt-2 col-12 col-md-6 col-lg-5">

            <div class="card h-100">
              <div class="card-body">
                <!--                <h2>Pending Claim</h2>-->
                <mat-icon *ngIf="userService.user.vest4_tx != 'false'" class="claimed-icon" aria-hidden="false" aria-label="Reef Claimed">check</mat-icon>
                <div class="mt-3 mb-3">
                  <h3>Q4 2021</h3>
                  <p>REEF amount</p>
                  <h1 class="token-number-text text-monospace">{{userService.user.vest_amount - userService.getVestSubtractAmount | number: '1.0-0'}}</h1>
                </div>
                <div>

                  <div *ngIf="!userService.user.ss58address">

                    <div *ngIf="!alreadyHave && !howToCreate">
                      <a class="btn font-weight-bold" href="https://wiki.polkadot.network/docs/learn-account-generation/#polkadotjs-browser-plugin" target="_blank">How do I create Reef Chain address?</a>
                      <button class="btn btn-primary font-weight-bold" (click)="alreadyHave = true">I already have Reef Chain address</button>
                    </div>
                    <div *ngIf="alreadyHave">
                      <p><b>Paste your Reef Chain Substrate address (SS58) below</b></p>
                      <input [disabled]="confirming" type="text" class="form-control mt-1 mb-2" [(ngModel)]="ss58Address" style="font-size: 13px">
                      <p class="no-margin mb-3" style="color: indianred" *ngIf="ss58AddressError">Address is invalid! <a href="https://wiki.polkadot.network/docs/learn-account-generation/#polkadotjs-browser-plugin" target="_blank">Check guide</a></p>
                      <button *ngIf="!confirming" class="btn btn-primary btn-block font-weight-bold" (click)="confirmSs58Address()">Confirm</button>
                      <button *ngIf="!confirming" class="btn btn-block font-weight-bold" (click)="alreadyHave = false">Back</button>

                    </div>
                    <!--                    <div *ngIf="howToCreate">-->

                    <!--                      <p><b>Navigate to the guide below</b></p>-->
                    <!--                      <button class="btn btn-primary btn-block font-weight-bold">Hot wallet</button>-->
                    <!--                      <button class="btn btn-primary btn-block font-weight-bold">Cold wallet</button>-->
                    <!--                      <button class="btn btn-block font-weight-bold" (click)="howToCreate = false">Back</button>-->
                    <!--                    </div>-->
                    <p *ngIf="confirming">Confirming...</p>
                    <!--                    <p class="text-center mt-2">If the address it not correct, please contact <a target="_blank" href="https://t.me/reef_claim_admin">@reef_claim_admin</a> on telegram.</p>-->
                  </div>
                  <div *ngIf="userService.user.ss58address">
                    <div *ngIf="userService.user.vest4_tx == 'false'" >
                      <p style="color: purple; font-weight: bolder">Your tokens will be distributed very soon!</p>
                      <p class="m-1">Address: <b>{{userService.user.ss58address}}</b></p>
                      <p><br>If the address it not correct, please contact <a target="_blank" href="https://t.me/reef_claim_admin">@reef_claim_admin</a> on telegram.</p>
                    </div>
                    <div *ngIf="userService.user.vest4_tx !== 'false'">
                      <p  style="color: purple; font-weight: bolder">Your tokens have been distributed.</p>
                      <!--                      <p class="m-1">Address: <b>{{userService.user.ss58address}}</b></p>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="mt-2 col-12 col-md-6 col-lg-5">
            <div class="card h-100">
              <div class="card-body">
                <!--                <h2>Pending Claim</h2>-->
                <mat-icon *ngIf="userService.user.vest3_tx != 'false'" class="claimed-icon" aria-hidden="false" aria-label="Reef Claimed">check</mat-icon>
                <div class="mt-3 mb-3">
                  <h3>Q3 2021</h3>
                  <p>REEF amount</p>
                  <h1 class="token-number-text text-monospace">{{userService.user.vest_amount - userService.getVestSubtractAmount | number: '1.0-0'}}</h1>
                </div>
                <div>

                  <div *ngIf="!userService.user.ss58address">

                    <div *ngIf="!alreadyHave && !howToCreate">
                      <a class="btn font-weight-bold" href="https://wiki.polkadot.network/docs/learn-account-generation/#polkadotjs-browser-plugin" target="_blank">How do I create Reef Chain address?</a>
                      <button class="btn btn-primary font-weight-bold" (click)="alreadyHave = true">I already have Reef Chain address</button>
                    </div>
                    <div *ngIf="alreadyHave">
                      <p><b>Paste your Reef Chain Substrate address (SS58) below</b></p>
                      <input [disabled]="confirming" type="text" class="form-control mt-1 mb-2" [(ngModel)]="ss58Address" style="font-size: 13px">
                      <p class="no-margin mb-3" style="color: indianred" *ngIf="ss58AddressError">Address is invalid! <a href="https://wiki.polkadot.network/docs/learn-account-generation/#polkadotjs-browser-plugin" target="_blank">Check guide</a></p>
                      <button *ngIf="!confirming" class="btn btn-primary btn-block font-weight-bold" (click)="confirmSs58Address()">Confirm</button>
                      <button *ngIf="!confirming" class="btn btn-block font-weight-bold" (click)="alreadyHave = false">Back</button>

                    </div>
                    <!--                    <div *ngIf="howToCreate">-->

                    <!--                      <p><b>Navigate to the guide below</b></p>-->
                    <!--                      <button class="btn btn-primary btn-block font-weight-bold">Hot wallet</button>-->
                    <!--                      <button class="btn btn-primary btn-block font-weight-bold">Cold wallet</button>-->
                    <!--                      <button class="btn btn-block font-weight-bold" (click)="howToCreate = false">Back</button>-->
                    <!--                    </div>-->
                    <p *ngIf="confirming">Confirming...</p>
                    <!--                    <p class="text-center mt-2">If the address it not correct, please contact <a target="_blank" href="https://t.me/reef_claim_admin">@reef_claim_admin</a> on telegram.</p>-->
                  </div>
                  <div *ngIf="userService.user.ss58address">
                    <div *ngIf="userService.user.vest3_tx == 'false'" >
                      <p style="color: purple; font-weight: bolder">Your tokens will be distributed very soon!</p>
                      <p class="m-1">Address: <b>{{userService.user.ss58address}}</b></p>
                      <p><br>If the address it not correct, please contact <a target="_blank" href="https://t.me/reef_claim_admin">@reef_claim_admin</a> on telegram.</p>
                    </div>
                    <div *ngIf="userService.user.vest3_tx !== 'false'">
                      <p  style="color: purple; font-weight: bolder">Your tokens have been distributed.</p>
                      <!--                      <p class="m-1">Address: <b>{{userService.user.ss58address}}</b></p>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          <div class="mt-2 col-12 col-md-6 col-lg-5">
            <div class="card h-100">
              <div class="card-body">
                <!--                <h2>Pending Claim</h2>-->
                <!--                <mat-icon class="claimed-icon" aria-hidden="false" aria-label="Reef Claimed">cached</mat-icon>-->
                <mat-icon class="claimed-icon" aria-hidden="false" aria-label="Reef Claimed">check</mat-icon>
                <div class="mt-3 mb-3">
                  <h3>Q2 2021</h3>
                  <p>REEF amount</p>
                  <h1 class="token-number-text text-monospace">{{userService.user.vest_amount - userService.getVestSubtractAmount | number: '1.0-0'}}</h1>
                </div>
                <div>

<!--                  <div *ngIf="!userService.user.vest2_distribution_confirmed">-->
<!--                    <p style="color: indianred" class="mb-3">This batch will be distributed on Reef Chain. You need to create and confirm your new address.</p>-->
<!--                    <div *ngIf="!alreadyHave && !howToCreate">-->
<!--                      <a class="btn font-weight-bold" href="https://wiki.polkadot.network/docs/learn-account-generation/#polkadotjs-browser-plugin" target="_blank">How do I create Reef Chain address?</a>-->
<!--                      <button class="btn btn-primary font-weight-bold" (click)="alreadyHave = true">I already have Reef Chain address</button>-->
<!--                    </div>-->
<!--                    <div *ngIf="alreadyHave">-->
<!--                      <p><b>Paste your Reef Chain Substrate address (SS58) below</b></p>-->
<!--                      <input [disabled]="confirming" type="text" class="form-control mt-1 mb-2" [(ngModel)]="ss58Address" style="font-size: 13px">-->
<!--                      <p class="no-margin mb-3" style="color: indianred" *ngIf="ss58AddressError">Address is invalid! <a href="https://wiki.polkadot.network/docs/learn-account-generation/#polkadotjs-browser-plugin" target="_blank">Check guide</a></p>-->
<!--                      <button *ngIf="!confirming" class="btn btn-primary btn-block font-weight-bold" (click)="confirmSs58Address()">Confirm</button>-->
<!--                      <button *ngIf="!confirming" class="btn btn-block font-weight-bold" (click)="alreadyHave = false">Back</button>-->

<!--                    </div>-->
<!--                    &lt;!&ndash;                    <div *ngIf="howToCreate">&ndash;&gt;-->

<!--                    &lt;!&ndash;                      <p><b>Navigate to the guide below</b></p>&ndash;&gt;-->
<!--                    &lt;!&ndash;                      <button class="btn btn-primary btn-block font-weight-bold">Hot wallet</button>&ndash;&gt;-->
<!--                    &lt;!&ndash;                      <button class="btn btn-primary btn-block font-weight-bold">Cold wallet</button>&ndash;&gt;-->
<!--                    &lt;!&ndash;                      <button class="btn btn-block font-weight-bold" (click)="howToCreate = false">Back</button>&ndash;&gt;-->
<!--                    &lt;!&ndash;                    </div>&ndash;&gt;-->
<!--                    <p *ngIf="confirming">Confirming...</p>-->
<!--                    &lt;!&ndash;                    <p class="text-center mt-2">If the address it not correct, please contact <a target="_blank" href="https://t.me/reef_claim_admin">@reef_claim_admin</a> on telegram.</p>&ndash;&gt;-->
<!--                  </div>-->
                  <div *ngIf="userService.user.vest2_distribution_confirmed">
                    <div *ngIf="userService.user.vest2_tx == 'false'" >
                      <p style="color: purple; font-weight: bolder">Confirmed. Your tokens will be distributed very soon!</p>
                      <p class="m-1">Address: <b>{{userService.user.ss58address}}</b></p>
<!--                      <p><br>If the address it not correct, please contact <a target="_blank" href="https://t.me/reef_claim_admin">@reef_claim_admin</a> on telegram.</p>-->
                    </div>
                    <div *ngIf="userService.user.vest2_tx !== 'false'">
                      <p  style="color: purple; font-weight: bolder">Your tokens have been distributed.</p>
<!--                      <p class="m-1">Address: <b>{{userService.user.ss58address}}</b></p>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-2 col-12 col-md-6 col-lg-5">
            <div class="card h-100">
              <div class="card-body">
                <mat-icon class="claimed-icon" aria-hidden="false" aria-label="Reef Claimed">check</mat-icon>
                <div class="mt-3 mb-3">
                  <h3>Q1 2021</h3>
                  <p>REEF amount</p>
                  <h1 class="token-number-text text-monospace">{{userService.user.vest_amount - userService.getVestSubtractAmount | number: '1.0-0'}}</h1>
                  <div *ngIf="userService.user.vest1_distribution_confirmed">
                    <p *ngIf="userService.user.vest1_tx == 'false'" style="color: purple; font-weight: bolder">Confirmed. Your tokens will be distributed very soon!</p>
                    <p *ngIf="userService.user.vest1_tx !== 'false'" style="color: purple; font-weight: bolder">Your tokens have been distributed.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-2 col-12 col-md-6 col-lg-5">
            <div class="card h-100">

              <div class="card-body">
                <mat-icon class="claimed-icon" aria-hidden="false" aria-label="Reef Claimed">check</mat-icon>

                <div class="mt-3 mb-3">
                  <h3>Initial Distribution</h3>
                  <p>REEF amount</p>
                  <h1 class="token-number-text text-monospace">{{userService.user.tge_amount - userService.getTgeSubtractAmount | number: '1.0-0'}}</h1>
                </div>

                <div *ngIf="userService.user.claimed && userService.user.claim_type == 2" class="mt-5">
<!--                  <div *ngIf="!userService.user.usdt_distribution_confirmed">-->
<!--                    <p>Please confirm the USDT address below.</p>-->
<!--                    <div class="mt-4 mb-5">-->
<!--                      <p class="text-center">Address</p>-->
<!--                      <p class="text-monospace" *ngFor="let addr of getAddresses">{{addr}}</p>-->
<!--                      <span style="color: purple" *ngIf="getAddresses.length > 1">The amount will be equally split between these two addresses.</span>-->
<!--                      <button *ngIf="!confirming" class="btn btn-primary btn-block font-weight-bold" (click)="confirmUsdtClaim()">Confirm</button>-->
<!--                      <p *ngIf="confirming">Confirming...</p>-->
<!--                    </div>-->
<!--                    <p class="text-center mt-2">If the address it not correct, please contact <a target="_blank" href="https://t.me/reef_claim_admin">@reef_claim_admin</a> on telegram.</p>-->
<!--                  </div>-->
                  <div *ngIf="userService.user.usdt_distribution_confirmed">
                    <p *ngIf="userService.user.usdt_tx == 'false'" style="color: purple; font-weight: bolder">Confirmed. Your USDT will be distributed very soon!</p>
                    <p *ngIf="userService.user.usdt_tx !== 'false'" style="color: purple; font-weight: bolder">Your USDT have been distributed.</p>
                  </div>
                </div>
                <div *ngIf="!claiming">
                  <p class="text-monospace" *ngIf="userService.user.claim_type == 2">Mechanism: Managed Selling</p>
                  <p class="text-monospace" *ngIf="userService.user.claim_type == 3">Mechanism: Manual Claim</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

