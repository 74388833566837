import {Component} from '@angular/core';
import {UserService} from '../../services/user.service';
import {UtilService} from '../../services/util.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-claim',
  templateUrl: './claim.component.html',
  styleUrls: ['./claim.component.scss']
})
export class ClaimComponent {
  //claimStep: number = 0;
  claiming: any = false;
  confirming: boolean = false;
  alreadyHave: any;
  howToCreate: boolean;
  ss58Address: any;
  ss58AddressError: boolean;

  constructor(public userService: UserService, public utilService: UtilService, public router: Router) {

  }

  async claimPrompt(claim_type) {
    let decision = this.getDecision(claim_type);
    let res = await this.utilService.showPrompt(decision);
    if (!res) {
      return;
    }
    this.claiming = claim_type;
    this.claimTokens(claim_type);
  }

  claimTokens(claim_type) {
    this.claiming = this.claiming || true;
    setTimeout(() => {
      this.userService.claim(claim_type).subscribe((data: any) => {
        this.claiming = false;
        //this.userService.user.claimed = true;
        //this.claimStep = 1;

        this.userService.user.claim_type = claim_type;
        this.userService.user.telegram_group_link = data.telegram_group_link;
        if (claim_type == 0) {
          this.userService.fetch(this.userService.code);
        }

      }, err => {
        this.claiming = false;
      });
    }, 2000);
  }

  private getDecision(claim_type: any) {
    if (claim_type == 2) {
      return {
        title: 'Claim mechanism: Managed Selling',
        subtitle: 'Are you sure?'
      };
    } else if (claim_type == 3) {
      return {
        title: 'Claim mechanism: Manual claim',
        subtitle: 'Are you sure?'
      };
    }
  }

  checkProceed() {
    if (!this.userService.user.summary_accepted) {
      this.utilService.showInfoMessage('First step missing', `Please indicate that the information under \'Allocation Summary\' is correct by checking "The information above is correct" and press Save. <br><img class="ss-image" src="/assets/screenshot1.png">`, 'Take me there', () => {
        this.router.navigate(['/allocation-summary']);
      });
      return;
    }
    this.userService.user.claim_type = 1;
  }

  get getAddresses() {
    return this.userService.user.address ? this.userService.user.address.split('|') : [this.userService.user.address];
  }

  async confirmManualClaim() {

    let confirmed = await this.utilService.showPrompt({
      title: 'Confirmation',
      subtitle: 'Are you sure?'
    });
    if (confirmed) {
      this.confirming = true;
      await this.utilService.timeout(1);
      this.userService.confirmVestDistribution().subscribe((data: any) => {
        this.confirming = false;
        if (data.success) {
          this.userService.user.vest1_distribution_confirmed = true;
        }
        this.utilService.showSuccessToastr("Confirmed. Your tokens will be distributed very soon!");
      }, err => {
        this.confirming = false;
      })
    }
  }

  async confirmUsdtClaim() {

    let confirmed = await this.utilService.showPrompt({
      title: 'Confirmation',
      subtitle: 'Are you sure?'
    });
    if (confirmed) {
      this.confirming = true;
      await this.utilService.timeout(1);
      this.userService.confirmUsdtDistribution().subscribe((data: any) => {
        this.confirming = false;
        if (data.success) {
          this.userService.user.usdt_distribution_confirmed = true;
        }
        this.utilService.showSuccessToastr("Confirmed. Your USDT will be distributed very soon!");
      }, err => {
        this.confirming = false;
      })
    }
  }

  validateSs58(ss58Address: any) {
    this.userService.validateSs58Address(ss58Address).subscribe(data => {
      console.log(data)
    }, err => {
      console.error(err);
    })
  }

  confirmSs58Address() {
    this.confirming = true;
    this.userService.validateSs58Address(this.ss58Address).subscribe((data: any) => {
      if (data.valid) {
        this.ss58AddressError = false;
        this.userService.confirmSs58Address(this.ss58Address).subscribe(data => {
          this.userService.user.ss58address = this.ss58Address;
          this.userService.user.vest2_distribution_confirmed = true;
          this.userService.user.vest3_distribution_confirmed = true;
          this.confirming = false;
        }, err => {
          this.confirming = false;
        })
      } else {
        this.ss58AddressError = true;
        this.confirming = false;
      }
    })
  }
}
