import {Component, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {MonitoringService} from '../../services/monitoring.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  navigations: any = [
    // { link: '/', icon: 'reef', title: 'Claim REEF' },
    // { link: '/allocation-summary', icon: 'settings', title: 'Allocation Summary' },

    // { link: '/otc-stats', icon: 'chart', title: 'OTC Stats' },
    // { link: '/launchpool', icon: 'binance', title: 'Launchpool Stats', class: ['mb-5'] },
    // { link: '/manage', icon: 'account', title: 'Manage users', hide: () => this.userService.user.child > 0 },
    //
    {link: '/stats', icon: 'chart', title: 'Stats'},
    {link: '/monitoring', icon: 'settings', title: 'Monitoring'},
    {link: '/monitoring-compare', icon: 'settings', title: 'Monitoring Compare'},
    {link: '/positions', icon: 'cash-stack', title: 'Positions'},

  ];
  mobileNavShown: boolean;

  navRoute: Observable<string>;
  nav: any;
  visible = true;

  reloadComponents() {
    this.visible = false;
    setTimeout(() => {
      this.visible = true;
    }, 500);
  }

  get getNavigations() {
    return this.navigations.filter(x => {
      if (x.hide) {
        return !x.hide();
      }
      return true;
    });
  }

  constructor(public userService: UserService, public monitoringService: MonitoringService, private router: Router) {
    let nav = this.navigations.find(x => x.link == router.url);
    if (nav) {
      this.nav = nav.title;
    }
    this.navRoute = this.router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      map(e => {
        let nav = this.navigations.find(x => x.link == e.url);
        return nav ? nav.title : '';
      }));
  }

  ngOnInit(): void {
  }

  signout() {
    this.userService.logout();
  }

  reload() {
    location.reload();
  }

  reinit() {
    setTimeout(() => {
      this.monitoringService.start();
      this.reloadComponents();
      this.mobileNavShown = !this.mobileNavShown;
    })
  }
}
