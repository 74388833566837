<!--<p>positions works!</p>-->
<!--<pre>-->
<!--  {{accounts | json}}-->
<!--</pre>-->

<div class="row p-3">
  <div class="col-sm-12">
    <p>Positions refreshed: {{accountService.positionsRefreshedTime | date: 'HH:mm:ss'}}</p>
    <button class="btn btn-secondary" (click)="accountService.forcePositionsRefresh()">Refresh</button>
    <p style="font-weight: bold">Margin:</p>
    <p>Total OM: {{totalMarginBorrowedOM | number}}</p>
    <p>Total USDT: {{totalMarginBorrowedUSDT | number}}</p>
    <p>Total Collateral USDT: {{totalMarginCollateralUSDT | number}}</p>

    <p style="font-weight: bold">Perp size:</p>
    <p>Total OM: {{totalPerpSize | number}}</p>
    <p>Total USDT: {{totalPerpSizeNotional | number}}</p>
    <p>Total Collateral USDT: {{totalPerpCollateralUSDT | number}}</p>

  </div>

  <!--  <button (click)="refresh()">Refresh</button>-->
</div>
<div class="row p-3">
  <div class="col-sm-12">
    <table class="table table-sm table-bordered">
      <tr>
        <td style="font-weight: bold">Name</td>
        <td style="font-weight: bold">Margin Level</td>
        <td style="font-weight: bold">Futures Liq. Price</td>
        <td style="font-weight: bold">Futures Entry Price</td>
        <td style="font-weight: bold">Futures PNL</td>
        <td style="font-weight: bold">Borrowed OM</td>
<!--        <td style="font-weight: bold">Borrowed USDT</td>-->
      </tr>
      <tr *ngFor="let acc of accounts">
        <td>{{acc.name}}</td>
        <td>{{acc.data?.margin?.marginAccount.marginLevel}}</td>
        <td>{{acc.perpLiquidationLevel}}</td>
        <td>{{acc.entryPrice | number: '0.0-2'}}</td>
        <td><span *ngIf="acc.unrealizedPnl">{{acc.unrealizedPnl | number: '0.0-0'}} <span style="float: right">[{{acc.percentage}}]%</span></span></td>
        <td>{{acc.data?.totalBorrowedOm | number: '0.0-0'}}</td>
<!--        <td>{{acc.totalMarginBorrowedUSDT | number: '0.0-0'}}</td>-->
      </tr>
    </table>
  </div>
</div>
<div class="row p-3">

  <div class="col-sm-3 mb-3" *ngFor="let acc of accounts">


    <div class="card text-left bg-success text-white h-100">
      <div class="card-body p-05rem">
        <h3 class="card-title">{{acc.name}} </h3>
      </div>
      <div class="p-2 font-weight-bold">Spot [Margin]</div>
      <div class="p-2">
        <div>Margin level: <span style="float: right">{{acc.data?.margin?.marginAccount.marginLevel | number: '0.0-2'}}</span></div>
        <div>Collateral USDT: <span style="float: right">{{acc.data?.margin?.marginAccount.totalCollateralValueInUSDT | number: '0.0-0'}}</span></div>

      </div>

      <div class="p-2 font-weight-bold">Assets</div>

      <ul class="list-group list-group-flush">
        <li  *ngFor="let asset of acc.data?.margin?.marginAccount.userAssets; let i = index"
            class="p-03rem bg-success-lighter list-group-item"><span
          style="font-size: 12px">{{asset.asset}}</span> <span style="float: right">{{asset.netAsset | number: '0.0-0'}}</span></li>
      </ul>
      <div class="p-2">Futures</div>
      <div class="p-2" *ngFor="let pos of acc.data?.positions">
        <div><b>{{pos.symbol}}</b></div>
        <div>Collateral: <span style="float: right">{{pos.collateral | number: '0.0-0'}}</span></div>
        <div>Entry Price: <span style="float: right">{{pos.entryPrice | number: '0.0-2'}}</span></div>
        <div>Position: <span style="float: right">{{pos.contracts | number: '0.0-0'}}</span></div>
        <div>Notional: <span style="float: right">{{pos.notional | number: '0.0-0'}}</span></div>
        <div>Unrealized: <span style="float: right">{{pos.unrealizedPnl | number: '0.0-0'}} [{{pos.percentage}}%]</span></div>
        <div>Liq. price: <span style="float: right">${{pos.liquidationPrice | number: '0.0-2'}}</span></div>
      </div>

    </div>
  </div>
</div>
