import { Component, OnInit } from '@angular/core';
import {MonitoringService} from '../../services/monitoring.service';

@Component({
  selector: 'app-monitoring-compare',
  templateUrl: './monitoring-compare.component.html',
  styleUrls: ['./monitoring-compare.component.scss']
})
export class MonitoringCompareComponent implements OnInit {
  deltasOnly: any = true;
  addressFilter: any;

  constructor(public monitoringService: MonitoringService) { }

  ngOnInit(): void {
  }

  formatSnapshotName(snapshot: any) {
    return snapshot.split('_').join('.');
  }

  filterAddresses(basketsToCompareWith: any) {
    return basketsToCompareWith.filter(q => {
      if (this.deltasOnly) {
        return q.deltaFound;
      }
      return true;
    })
  }

  basketToggle(basket) {
    let a = this.monitoringService.baskets.find(b => b.name === basket.name);
    let b = this.monitoringService.basketsToCompareWith.find(b => b.name === basket.name);
    a.show = !a.show;
    b.show = !b.show;
  }
}
