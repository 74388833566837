import { Component, OnInit } from '@angular/core';
import {UserService} from "../../services/user.service";
import {Router} from "@angular/router";
import {MonitoringService} from '../../services/monitoring.service';

@Component({
  selector: 'app-code-login',
  templateUrl: './code-login.component.html',
  styleUrls: ['./code-login.component.scss']
})
export class CodeLoginComponent implements OnInit {
  manualCode;

  constructor(public userService: UserService, private router: Router, public monitoringService: MonitoringService) {
    this.userService.loggedEmitter.subscribe(data => {
      this.router.navigate(['/']);
    })
  }

  ngOnInit(): void {
  }

}
