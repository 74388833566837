import {Component} from '@angular/core';
import {UserService} from "../../services/user.service";
import {UtilService} from "../../services/util.service";

@Component({
  selector: 'app-allocation-summary',
  templateUrl: './allocation-summary.component.html',
  styleUrls: ['./allocation-summary.component.scss']
})
export class AllocationSummaryComponent {

  summary_accepted: any;
  withoutSplits: any = true;
  get hasChildren() {
    return this.userService.user.children.filter(x => x.code).length;
  }

  get totalSupply() {
    return (this.getVestAmount * 4) + this.getTgeAmount;
  }
  get getTgeAmount() {
    return this.withoutSplits ? this.userService.user.tge_amount: this.userService.user.tge_amount - this.userService.getTgeSubtractAmount

  }

  get getVestAmount() {
    return this.withoutSplits ? this.userService.user.vest_amount: this.userService.user.vest_amount - this.userService.getVestSubtractAmount;
  }
  get getTokenState() {
    if (this.userService.user.tge_amount) {
      return [
        {name: 'Token Generation Event', amount: this.getTgeAmount},
        {name: 'Vest Period Amount', amount: this.getVestAmount},
        {name: 'Vesting periods (Quarters)', amount: 4},
        {name: 'Total', amount: this.totalSupply},
      ];
    }
  }

  get terms() {
    return [
      {name: 'Private', price: 0.00125},
      {name: 'Strategic', price: 0.0009},
      {name: 'Seed', price: 0.0007},
    ];
  }

  constructor(public userService: UserService, public utilService: UtilService) {

  }

  get getAddresses() {
    return this.userService.user.address ? this.userService.user.address.split('|') : [];
  }


  saveSummary() {
    this.userService.saveSummary(this.userService.user.code).subscribe(data => {
      this.userService.user.summary_accepted = true;
      this.utilService.showInfoMessage('Done');
    });
  }


}
