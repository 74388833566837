import { Component, OnInit } from '@angular/core';
import {UtilService} from "../../services/util.service";
import {UserService} from "../../services/user.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-children-management',
  templateUrl: './children-management.component.html',
  styleUrls: ['./children-management.component.scss']
})
export class ChildrenManagementComponent implements OnInit {
  get getSplitAmount() {
    let supply = 0;

    this.userService.user.children.forEach(x => {
      if (x.percentage)
         supply += this.userService.user.tge_amount * x.percentage / 100
    });
    return supply;
  }

  constructor(public utilService: UtilService, public userService: UserService, private router: Router) { }

  ngOnInit(): void {
    this.userService.loggedEmitter.subscribe(data => {
      if (this.userService.user.child > 0) {
        this.router.navigate(['/allocation-summary']);
      }
    })
  }

  newSplit() {
    if (this.userService.user.children.filter(x => !x.code).length > 0) {
      return this.utilService.showInfoMessage("Hold on", "Please save the already opened entry")
    }
    this.userService.user.children.unshift({percentage: 1, parent_code: this.userService.code})
  }

  async createChild(child: any) {
    let messages = this.validateChild(child);
    if (messages.length > 0) {
      return this.utilService.showInfoMessage("Errors", messages.join('<br>'));
    }
    child.saving = true;
    await this.utilService.timeout(0.5);
    this.userService.createChild(child).subscribe(data => {
      this.utilService.showSuccessToastr("Saved. You can now copy the link and send it");
      delete child.saving;
      Object.assign(child, data);
    }, err => {
      child.saving = false;
    });
  }

  spliceChild(child) {
    let ind = this.userService.user.children.findIndex(x => x.code == child.code);
    this.userService.user.children.splice(ind, 1);
  }
  async deleteChild(child: any) {
    let res = await this.utilService.showPrompt({title: "Are you sure?", subtitle: "This user wont be able to access his link anymore."});
    if (!res) return ;
    child.deleting = true;
    await this.utilService.timeout(0.5);
    if (child.code) {
      this.userService.deleteChild(child.code).subscribe(data => {
        this.spliceChild(child);
      }, err => {
        child.deleting = false;
      })
    } else {
      this.spliceChild(child);
    }
  }

  getLink(child: any) {
    return 'http://'+ window.location.hostname + '?code=' + child.code;
  }

  linkCopied(child) {
    child.copied = true;
    setTimeout(() => {
      child.copied = false;
    }, 1000)
  }


  private validateChild(child: any) {
    let messages = [];
    if (!child.percentage || child.percentage < 0.1 || child.percentage > 100) {
      messages.push("Percentage must be between 0.1 and 100")
    }
    if (!child.name) {
      messages.push("Name is required")
    }
    if (!child.address || !WAValidator.validate(child.address, 'ETH')){
      messages.push("Ethereum address is invalid")
    }
    return messages;
  }

}
declare var WAValidator;
