import { Component, OnInit } from '@angular/core';
import {MonitoringService} from '../../services/monitoring.service';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {
  liquid: any = {}
  vested: any = {};
  totalOur = 0;
  checkedAggregateAnalysis: any;
  constructor(public monitoringService: MonitoringService) { }
  relDiff(priceSold, priceBought) {
    //return 100 * Math.abs((a - b) / ((a + b) / 2));
    return (priceSold - priceBought) / priceBought * 100
  }
  ngOnInit(): void {
    // this.liquid = <any>this.monitoringService.baskets.find(q => q.name == 'Treasury Liquid')
    // this.vested = <any>this.monitoringService.baskets.find(q => q.name == 'Treasury Vested')
    // console.log(this.monitoringService.baskets, "maskets", this.liquid, this.vested)
    // this.totalOur = this.liquid.data.amount + this.vested.data.amount;
    // console.log(this.totalOur, "yeeyeeyey")
  }
  resetChecked() {
    this.monitoringService.analyses.forEach(analysis => {
      analysis.checkedForAgg = false;
    })
    this.checkedAggregateAnalysis = null;
  }
  calculateAggregatePnl() {

    let total: any = { account: "TOTAL", currentPosition: 0, totalPositionCost: 0 };
    let l = this.monitoringService.analyses.filter(q => q.checkedForAgg);
    if (l.length == 0) return;
    l.filter(q => q.checkedForAgg).forEach(analysis => {
      total.currentPosition += analysis.currentPosition;
      total.totalPositionCost += analysis.totalPositionCost;
    });
    total.avgBuyPrice = total.totalPositionCost / total.currentPosition;
    total.averagePositionPrice = total.totalPositionCost / total.currentPosition;
    total.profitPercentage = this.relDiff(this.monitoringService.priceOm?.usd, total.averagePositionPrice);
    total.unrealizedPnl = total.currentPosition * (this.monitoringService.priceOm?.usd - total.avgBuyPrice);
    total.totalPositionValue = total.currentPosition * this.monitoringService.priceOm?.usd;
    //return total;
    this.checkedAggregateAnalysis = total;
  }

  checkedAggregateAnalysisList() {
    let list = [];
    this.monitoringService.analyses.filter(q => q.checkedForAgg).forEach((analysis) => {
      list.push(analysis.account);
    })
    return `[${list.length}]: `+ list.join(', ');
  }

}

